@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,800,900|Nunito+Sans:300,400,600,700,900&display=swap');

$width-main: 90%;

$color-bz-green: #009C3B;
$color-bz-yellow: #FFDF00;
$color-bz-yellow-subtle: rgb(255, 246, 185);
$color-bz-blue: #002776;
$color-dark-purple: #543243;
$color-mid-purple: #A17088;

$font-header: 'Montserrat', sans-serif;
$font-main: 'Nunito Sans', sans-serif;

html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

body {

  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image:
    radial-gradient(
      ellipse at center,
      rgba(253, 253, 253,0.9),
      rgba(200, 200, 200,0.4),
  );
  background-color: white;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#root {

  width: 100vw;
  font-family: $font-main;
}

// ------------ HEADER SECTION ------------

.header-section{
  width: 100vw;
  padding: 3vmin 0 0 0;

  text-align: center;
  color: white;
  background-color: $color-dark-purple;

  h1 {
    padding: 3vmin 0;
    font-family: $font-header;
    font-size: calc(24px + (36 - 24) * ((100vw - 300px) / (1600 - 300)));
    line-height: calc(24px + (36 - 24) * ((100vw - 300px) / (1600 - 300)));
  }
  
  details {
    padding: 1vmin 0;
    font-size: calc(18px + (28 - 18) * ((100vw - 300px) / (1600 - 300)));
    color: #543243;
    background-color: rgb(212, 212, 212);
    transition: 0.3s;
    summary {
      font-weight:  700;
      color: $color-dark-purple;

      &:hover {
        color: $color-mid-purple;
      }
    }
    p {
      padding: 2vmin 5vmin;
      font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));

    }
  }

  .logo {
    width: 100px;
    margin: 0 auto;
  }
}

// ------------ FORM SECTION ------------

.unit-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  margin: 0 auto;

}

section {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  width: 40%;
  max-width: 600px;
  min-width: 300px;
  margin: 0 auto;
  padding: 1.5vmin;

  h1 {
    padding: 2vmin 0;
    text-align: center;
  }

  h2 {
    font-size: 1.4rem;
    margin: 0.2rem 0;
  }

  .amount-span {
    min-width: 25rem;
    font-weight: 700;
  }

  .to-section {
    // margin: 0.1rem 0;
    font-size: 1.2rem;
    font-weight: 700;
  }

  input[type=number] {
    width: 5rem;
    min-width: 1rem;
    margin: 1vmin 2vmin;
    padding: 0.3rem 0.8rem 0.3rem 1.4rem;

    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: #fff;
    background-color: $color-dark-purple;
    // background: linear-gradient(0deg, rgba(153,153,153,1) 0%, rgba(216,164,172,0.1) 0%, rgba(255,255,255,1) 100%);
  }

}

.select-from, .select-to {
  width: 40rem;
  max-width: 60%;
  min-width: 15rem;
  margin: 0 0.4rem;
  padding: .6rem 1.6rem .5rem .8rem;
  border: 1px solid #aaa;
  
	font-size: 1.3rem;
	font-family: sans-serif;
	font-weight: 600;
	color: #444;
  line-height: 1.3;

	box-sizing: border-box;

	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;

    transition: 0.2s;

  option {
    font-weight:normal;
  }

  &:hover {
    border-color: $color-dark-purple;
  }

  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  &::-ms-expand {
    display: none;
  }
}

section.conversion-section {
  max-width: 2rem;
  margin-top: 3vmin;

  // color: rgb(247, 243, 177);
  color: $color-bz-yellow-subtle;
  background-color: $color-mid-purple;
  border-radius: 20px;

  transition: 0.5s;


  .converted-val {
    margin: 0 2vmin;
    
    font-size: 1.4rem;
    font-weight: 700;

    color: white;

  }
}

// ------------ LANGUAGE SECTION ------------

.language-select {
  margin: 0 auto;
  padding: 2vmin 0 0 0;
  text-align: center;

  h2 {
    padding: 2vmin ;
    font-size: 1.1rem;
    font-weight: 700;
  }

  select  {
    width: 8rem;
    padding: 0.5vmin 2vmin;
    color: $color-dark-purple;
    font-weight: 700;
    font-size: 1.1rem;

  }

}

// ------------ SHARE SECTION ------------

.share-select {
  width: 100vw;
  margin: 0 auto;
  padding: 1.5vmin;
  font-size: 1rem;
}

.sc-bdVaJa  {
  font-size: 1.3rem;
  border-radius: 50% !important;
  padding: 0.5em 0.5em !important;

    &:hover {
      filter: brightness(1.2);
      transform: scale(1.2);
      transition: 0.4s;
  }
}

//  .___  ___.  _______  _______   __       ___           ______      __    __   _______ .______       __   _______     _______.
//  |   \/   | |   ____||       \ |  |     /   \         /  __  \    |  |  |  | |   ____||   _  \     |  | |   ____|   /       |
//  |  \  /  | |  |__   |  .--.  ||  |    /  ^  \       |  |  |  |   |  |  |  | |  |__   |  |_)  |    |  | |  |__     |   (----`
//  |  |\/|  | |   __|  |  |  |  ||  |   /  /_\  \      |  |  |  |   |  |  |  | |   __|  |      /     |  | |   __|     \   \    
//  |  |  |  | |  |____ |  '--'  ||  |  /  _____  \     |  `--'  '--.|  `--'  | |  |____ |  |\  \----.|  | |  |____.----)   |   
//  |__|  |__| |_______||_______/ |__| /__/     \__\     \_____\_____\\______/  |_______|| _| `._____||__| |_______|_______/    
//                                                                                                                              
//   ______   ___     ___      .______   ___   ___                                                                              
//  |____  | |__ \   / _ \     |   _  \  \  \ /  /                                                                              
//      / /     ) | | | | |    |  |_)  |  \  V  /                                                                               
//     / /     / /  | | | |    |   ___/    >   <                                                                                
//    / /     / /_  | |_| |    |  |       /  .  \                                                                               
//   /_/     |____|  \___/     | _|      /__/ \__\                                                                              
//                                                                                                                              
//                                                                                                                              
//           

@media only screen and (max-width: 720px) {

  .header-section {
    padding: 5vmin 0 0 0;

    h1 {
      padding: 1.5vmin 0;
    }

    details {
      margin: 3vmin 0 0 0;
      padding: 1vmin 1vmin;
    }

  }

  img.logo {
    max-width: 80px;
    padding: 1vmin 0;
  }

  .amount-span {
    padding: 4vmin 0 2vmin 0;
  }

  
  section input[type=number] {
    padding: 0 1vmin;
  }
}